import { useState } from 'react';
import Footer from './Footer';
import './App.css';

function App() {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (!email) {
      alert('Please enter a valid email address');
      return;
    }
  
    setLoading(true);
    try {
      const response = await fetch('https://script.google.com/macros/s/AKfycbzKvSst3t1ODEBs081IoOlfEFnOXMCLMBuSW0TBN7GNDqooN5ElDUQkBhFJ50wB4s-D/exec', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
        mode: 'no-cors'
      });
      alert('Successfully joined the waitlist!');
      setEmail('');
    } catch (error) {
      alert('An error occurred. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <nav className="navbar">
          <div className="logo">
            <img src={`${process.env.PUBLIC_URL}/robotskills-logo-512.png`} alt="Logo" className="logo-image" />
          </div>
          <ul className="nav-links">
            <li><a href="#about">About</a></li>
            <li><a href="https://app.reclaim.ai/m/tkornuta/coffee-chat">Contact</a></li>
          </ul>
        </nav>
      </header>

      <div className="hero">
        <div className="hero-content">
          <h1>Welcome to Skillware</h1>
          <p>Sharing Economy for Robot Skills.</p>
        </div>
      </div>

      <section id="about" className="about">
        <h2>Robots deployed in our homes and offices will require a plethora of production-grade skills</h2>
        <p>Robots have to master (new) skills on-the-fly and be able to generalize over new environments / scenes / objects / affordances / tasks / commands</p>
        <img src={`${process.env.PUBLIC_URL}/robot-operation.png`} alt="robot operation" className="image-container" />
      </section>

      <div className="first">
        <h2>Training of robust skills requires lots of compute</h2>
        <p>Skillware enables you to leverage the compute available on your robots when they are in Idle mode (charging, waiting for next command, task etc.)</p>
        <p>Use that compute for tasks related to robot skill development (off-line training, synthetic data generation, skill evaluation etc.)</p>
        <img src={`${process.env.PUBLIC_URL}/robot-idle-operation.png`} alt="robot operation" className="image-container" />
      </div>

      <div className="second">
        <h2>Access all the available compute</h2>
        <p>Skillware enables you to utilize the compute of all your robots, even if they are heterogeneous, and develop skills in a federated way</p>
        <img src={`${process.env.PUBLIC_URL}/multi-robot-development.png`} alt="robot operation" className="image-container" />
      </div>

      <div className="hero">
        <div className="hero-content">
          <p>Want to learn more?</p>
          <div className="cta-buttons">
            <div className="waitlist-input-group">
              <input
                type="email"
                placeholder="Enter your email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                disabled={loading}
                className="waitlist-input"
              />
              <button className="cta-button" onClick={handleSubmit} disabled={loading}>
                {loading ? 'Submitting...' : 'Join Waitlist'}
              </button>
            </div>
            <a className="cta-button" href="https://app.reclaim.ai/m/skillware/demo">Request a Demo</a>
          </div>
        </div>
      </div>



      <Footer />
    </div>
  );
}

export default App;
